import React, { useEffect, useState } from "react";
import CommonTable from "../../../../component/CommonTable/CommonTable"
import styles from "./PayRegister.module.scss"
import request from "../../../../utils/axios";
import { Checkbox, DatePicker, Select, Spin, Table } from "antd";
import dayjs from "dayjs";
import MultiSelectCheckboxDropdown from "../../../../component/MultiSelectCheckboxDropdown/MultiSelectCheckboxDropdown";
import activeDownload from "../../../../assets/activeDownload.png";
import { LoadingOutlined } from "@ant-design/icons";

function PayRegister({ tabsValue }) {
    const [payRegisterColumns, setPayRegisterColumns] = useState([
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
            fixed: 'left',
            width: 200
        },
        {
            title: 'Employee No.',
            dataIndex: 'employee_number',
            key: 'employee_number',
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Joining Date',
            dataIndex: 'joining_date',
            key: 'joining_date',
            render: (text) => dayjs(text).format('DD-MM-YYYY')
        },
        {
            title: 'Gross Salary',
            dataIndex: 'gross_salary',
            key: 'gross_salary',
        },
        {
            title: 'Net Salary',
            dataIndex: 'net_salary',
            key: 'net_salary',
        },
        {
            title: 'LOP',
            dataIndex: 'lop',
            key: 'lop',
        },
        {
            title: 'TDS',
            dataIndex: 'tds',
            key: 'tds',
        },
    ])
    // const payRegisterColumn = [

    // ]
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [payregisterData, setPayRegisterData] = useState([])
    const [processingDate, setProcessingDate] = useState({
        month: dayjs().format('MM'),
        year: dayjs().format('YYYY'),
    });
    const fetchPayRegisterData = async () => {
        try {


            const params = new URLSearchParams();
            params.append('month', processingDate.month);
            params.append('year', processingDate.year);

            if (selectedDepartment && selectedDepartment.length > 0) {
                params.append('departments', selectedDepartment.join(','));
            }

            if (selectedFunction && selectedFunction.length > 0) {
                params.append('functions', selectedFunction.join(','));
            }

            if (selectedGrades && selectedGrades.length > 0) {
                params.append('grades', selectedGrades.join(','));
            }

            if (selectedWorkLocation && selectedWorkLocation.length > 0) {
                params.append('work_locations', selectedWorkLocation.join(','));
            }

            if (selectedDesignation && selectedDesignation.length > 0) {
                params.append('designations', selectedDesignation.join(','));
            }


            console.log("params...", params.toString())
            let data = await request({
                method: "get",
                // url: `payroll/admin/get-pay-register/all?
                //         month=${processingDate.month}
                //         &year=${processingDate.year}
                //         &departments=${selectedDepartment}
                //         &functions=${selectedFunction}
                //         &grades=${selectedGrades}
                //         &work_locations=${selectedWorkLocation}
                //         &designations=${selectedDesignation}`,
                url: `payroll/admin/get-pay-register/all?${params.toString()}`
            })
            setPayRegisterData(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    // This function triggers the CSV download
    const downloadCSV = async () => {
        try {
            setDownloadLoader(true)
            const params = new URLSearchParams();
            params.append('month', processingDate.month);
            params.append('year', processingDate.year);
            if (selectedDepartment && selectedDepartment.length > 0) {
                params.append('departments', selectedDepartment.join(','));
            }

            if (selectedFunction && selectedFunction.length > 0) {
                params.append('functions', selectedFunction.join(','));
            }

            if (selectedGrades && selectedGrades.length > 0) {
                params.append('grades', selectedGrades.join(','));
            }

            if (selectedWorkLocation && selectedWorkLocation.length > 0) {
                params.append('work_locations', selectedWorkLocation.join(','));
            }

            if (selectedDesignation && selectedDesignation.length > 0) {
                params.append('designations', selectedDesignation.join(','));
            }

            const response = await request({
                method: "get",
                url: `payroll/admin/get-pay-register/export-csv?${params.toString()}`
            });
            const csvData = new Blob([response], { type: "text/csv" }); // Set MIME type to text/csv
            const url = window.URL.createObjectURL(csvData);
            const link = document.createElement("a");
            link.href = url;
            link.download = "pay_register.csv"; // Change filename to .csv
            link.click();
            setDownloadLoader(false)
        }
        catch (err) {
            console.log('CSV download failed: ', err);
            setDownloadLoader(false)
        }
    };

    useEffect(() => {
        if (tabsValue === "13") {
            fetchPayRegisterData()
        }
    }, [processingDate, tabsValue])

    const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
    };

    const [selectedItems, setSelectedItems] = useState([]);

    const handleChange = (value) => {
        setSelectedItems(value);
    };

    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [functionOptions, setFunctionOptions] = useState([]);
    const [gradesOptions, setGradesOptions] = useState([]);
    const [workLocationOptions, setWorkLocationOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);

    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedWorkLocation, setSelectedWorkLocation] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState([]);

    const fetchPayrollRegisterColumn = async () => {
        try {
            const data = await request({
                method: "get",
                url: "payroll/admin/payroll/pay-register/columns",
            })
            // setMobileLeaveColumList(data.data)
            setPayRegisterColumns([...payRegisterColumns, ...data.data])
            // fetchLeaveReportData();
        }
        catch (err) {
            console.log(err);
        }
    }

    const fetchDepartmentOptions = async () => {
        try {
            let data = await request({
                method: "get",
                url: "admin/employee/department/all",
            })
            setDepartmentOptions(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchFunctionOptions = async () => {
        try {
            let data = await request({
                method: "get",
                url: "admin/employee-function/all",
            })
            setFunctionOptions(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchGradesOptions = async () => {
        try {
            let data = await request({
                method: "get",
                url: "admin/grades/all",
            })
            setGradesOptions(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchWorkLocationOptions = async () => {
        try {
            let data = await request({
                method: "get",
                url: "admin/employee/work-location/all",
            })
            setWorkLocationOptions(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchDesignationOptions = async () => {
        try {
            let data = await request({
                method: "get",
                url: "admin/designation/all",
            })
            setDesignationOptions(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const applyFilter = async () => {
        try {
            console.log(selectedDepartment)
            console.log(selectedFunction)
            console.log(selectedGrades)
            console.log(selectedWorkLocation)
            console.log(selectedDesignation)

            fetchPayRegisterData();

        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchDepartmentOptions();
        fetchFunctionOptions();
        fetchGradesOptions();
        fetchWorkLocationOptions();
        fetchDesignationOptions();
        fetchPayrollRegisterColumn();
    }, [])

    return (

        <div>
            <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Pay Register</p>
                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        <DatePicker
                            size="large"
                            picker="month"
                            defaultValue={dayjs()}
                            style={{
                                // border: "none",
                                outline: "none",
                                // background: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#000000",
                                width: "200px"
                            }}
                            onChange={(e) => {
                                console.log("e...", e);
                                const [calendarYear, calendarMonth] = dayjs(e)
                                    .format("YYYY-MM")
                                    .split("-");
                                setProcessingDate({
                                    ...processingDate,
                                    month: calendarMonth,
                                    year: calendarYear
                                })
                            }}
                            // can we set default value as month name
                            // defaultValue={moment().format("MM")}
                            // defaultValue={dayjs().startOf('month')}
                            placeholder="Month"
                            format={"MMMM YYYY"}
                        />
                        {
                            downloadLoader
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "#008080" }} />} />
                                :
                                <img src={activeDownload} alt="activeDownload" width={20} height={20} onClick={downloadCSV} />
                        }
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "10px", marginBottom: "20px" }}>
                    <MultiSelectCheckboxDropdown
                        options={departmentOptions}
                        onChange={setSelectedDepartment}
                        placeholder="Department"
                    />

                    <MultiSelectCheckboxDropdown
                        options={functionOptions}
                        onChange={setSelectedFunction}
                        placeholder="Function"
                    />

                    <MultiSelectCheckboxDropdown
                        options={gradesOptions}
                        onChange={setSelectedGrades}
                        placeholder="Grades"
                    />

                    <MultiSelectCheckboxDropdown
                        options={workLocationOptions}
                        onChange={setSelectedWorkLocation}
                        placeholder="Work Location"
                    />

                    <MultiSelectCheckboxDropdown
                        options={designationOptions}
                        onChange={setSelectedDesignation}
                        placeholder="Designation"
                    />

                    <button
                        style={{
                            background: "#008080",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontWeight: "600"
                        }}
                        onClick={() => applyFilter()}
                    >
                        Apply
                    </button>
                </div>
                <Table
                    columns={payRegisterColumns}
                    dataSource={payregisterData}
                    scroll={{ x: 1800 }}
                />
            </div>
        </div>
    )
}

export default PayRegister